import { errorAndNotification } from '../error'
import { call, put, takeEvery, all } from 'redux-saga/effects'
import { injectorService } from '../../service/injector/injectorService'
import { UPDATE_PRODUCT, updateProductSuccess } from '../../actions/grid/product/update'
import { PatchPayload } from '../../actions/grid/product/update'
import { GridType } from 'src/types/index'
import { trackEvent } from '../../service/analytics/analytics'
import { getProductPatchErrorMessage } from 'service/mappers/product'
import { APIError, WholesaleError } from 'types/Api'
import { SVPAction } from 'actions/svp'
import { clearClipboardGridDestination } from 'actions/clipboard'

const errorMessageFor = (errorType: string) =>
  `Sorry something has broken in the tech and we couldn't ${errorType}. Please retry.`

export const PRODUCT_NO_LONGER_EXISTS_IN_ODBMS = 'Product no longer exists in ODBMS'

export const PRODUCT_NOS_PURGED_IN_ODBMS =
  'All product numbers associated to this product no longer exist in ODBMS'

export const REFRESH_VIEW_ERROR_MESSAGE = 'Admin may have updated the view, please refresh page'

export const TEMP_PRODUCT_NUMBER_ALREADY_EXISTS = 'Product number already exists'

export const UPDATE_PRODUCT_ERROR_MESSAGE = errorMessageFor('update product for you')

export function* updateProductSaga(action: SVPAction<typeof UPDATE_PRODUCT>) {
  trackEvent('product', 'Product Fields Update', action.payload.productSlug)

  try {
    yield* updateProduct(action.payload, action.grid)
  } catch (error) {
    const apiError = error as APIError & WholesaleError
    if (!apiError.response && !apiError.data) {
      yield call(errorAndNotification, apiError, UPDATE_PRODUCT_ERROR_MESSAGE)
    } else if (apiError?.response?.status === 500) {
      // In the event of a real Internal Server Error we wont have a proper response body
      yield* errorAndNotification(apiError, UPDATE_PRODUCT_ERROR_MESSAGE)
    } else {
      const statusCode = apiError?.response?.data?.statusCode || apiError?.data?.statusCode
      const errorCode = apiError?.response?.data?.error?.code || apiError?.data?.error?.code
      const errorMessage = getProductPatchErrorMessage(errorCode?.toString())
      if (statusCode === 400) {
        if (errorCode === 1) {
          yield call(errorAndNotification, apiError, REFRESH_VIEW_ERROR_MESSAGE)
        } else if (errorCode === 14) {
          yield call(errorAndNotification, apiError, PRODUCT_NO_LONGER_EXISTS_IN_ODBMS)
        } else if (errorCode === 18) {
          yield call(errorAndNotification, apiError, PRODUCT_NOS_PURGED_IN_ODBMS)
        } else if (errorCode === 8) {
          yield call(errorAndNotification, apiError, TEMP_PRODUCT_NUMBER_ALREADY_EXISTS)
        } else if (errorCode && errorMessage) {
          yield call(errorAndNotification, errorMessage, errorMessage)
        } else {
          yield call(errorAndNotification, '', 'Something went wrong. Please try again later.')
        }
      } else {
        yield* errorAndNotification(apiError, UPDATE_PRODUCT_ERROR_MESSAGE)
      }
    }
  }

  yield put(clearClipboardGridDestination())
}

export function* updateProduct({ productSlug, patchFields }: PatchPayload, grid: GridType) {
  yield call(injectorService.patch, `products/${productSlug}`, patchFields.api || patchFields.store)
  yield put(updateProductSuccess(productSlug, patchFields.store, grid))
}

export default function* () {
  yield all([takeEvery(UPDATE_PRODUCT, updateProductSaga)])
}
